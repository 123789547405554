import { RedocStandalone } from 'redoc'
import { theme } from './Theme'

function App() {
  const basePath = process.env.REACT_APP_IMPORT_FROM === 'INTERNAL'
    ? 'http://localhost:8080'
    : 'https://docs.workstreams.ai'

  return (
    < RedocStandalone
      specUrl={`${basePath}/jsons/api.json`}
      options={{theme}}
    />
  )
}

export default App
