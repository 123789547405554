const offWhite = '#e8ebf2'
const paleBlue = '#cad6e8'
const teal = '#3fd4ad'
const lightTeal = '#28e0d7'
const mediumTeal = '#23ccc4'
const darkTeal = '#04B5AC'
const red = '#f26d7d'
const yellow = '#ffd15d'
const blue = '#2da7dd'
const darkGrey = '#3c414f'
const mediumGrey = '#4a515e'

module.exports = {
    nativeScrollbars: true,
    theme: {
        colors: {
            primary: {
                // headers (h2) and download button
                main: offWhite,
            },
            success: {
                // 200 Successful operation drop down and 200 text on button of Response samples 
                main: teal,
            },
            error: {
                // required text under parameters 
                main: red,
            },
            text: {
                // majority of all text in main center section and the bearerAuth table
                primary: offWhite,
                // prop text in center area
                secondary: offWhite,
            },
            http: {
                delete: red,
                get: teal,
                patch: yellow,
                put: yellow,
                post: blue,
            },
        },
        schema: {
            linesColor: lightTeal,
            typeTitleColor: lightTeal,
        },
        typography: {
            fontFamily: 'proxima-nova, sans-serif',
            fontSize: '14px',
            fontWeightBold: '600',
            headings: {
                fontFamily: 'proxima-nova, sans-serif',
                fontWeight: '800',
            },
            links: {
                color: darkTeal,
                hover: paleBlue,
                visited: darkTeal,
            }
        },
        sidebar: {
            backgroundColor: darkGrey,
            textColor: paleBlue,
            activeTextColor: mediumTeal,
            fontSize: '13px',
            arrow: {
                size:'17px',
                color: paleBlue,
            }
        },
        codeBlock: {
            backgroundColor: darkGrey,
        },
        logo: {
            maxHeight: '80%',
            maxWidth: '90%',
            gutter: '18px',
        }, 
        rightPanel: {
            backgroundColor: mediumGrey,
            width: '40%',
        },

    },
}